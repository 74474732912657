/* @import "~antd/dist/antd.css"; */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.automations-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
}

.app-message-container {
  display: flex;
  justify-content: center;
}

.app-danger-text {
  color: red;
  font-size: 20px;
}

.app-success-text {
  color: green;
  font-size: 20px;
}

.app-default-text {
  color: blue;
  font-size: 20px;
}

.add-automation-container {
  text-align: end;
}

.button-primary {
  background-color: #0b5394;
  border-color: #0b5394;
  color: #fff;
}

.button-warning {
  background-color: #faad14;
  border-color: #faad14;
}

.button-warning:hover {
  background-color: #ffc53d;
  border-color: #ffc53d;
}

.button-success {
  background-color: #52c41a;
  border-color: #52c41a;
}

.button-success:hover {
  background-color: #73d13d;
  border-color: #73d13d;
}

.search-container {
  width: 20%;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
